var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('service-introduction',{staticClass:"service-introduction",attrs:{"introductionData":_vm.introductionData,"showTab":_vm.showTab}}),_c('div',{staticClass:"suitable"},[_c('div',{staticClass:"suitable-content"},[_c('div',{staticClass:"suitable-content-left"},[_c('div',{staticClass:"suitable-content-name"},[_c('div',{staticClass:"suitable-content-spname"},[_vm._v(_vm._s(_vm.smartEquipmentsData.title))]),_c('div',{staticClass:"suitable-content-egname"},[_vm._v(_vm._s(_vm.smartEquipmentsData.egtitle))])]),_c('div',{staticClass:"suitable-content-introduction"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.smartEquipmentsData.message)}})])]),_c('div',{staticClass:"suitable-content-middle"},[_c('div',{staticClass:"suitable-content-right-image",style:({'background-image': 'url('+_vm.smartEquipmentsData.image1+')',
                     'width':'100%','height':'100%','background-size':'contain',
                     'background-repeat':'no-repeat','background-position':'center'})})]),_c('div',{staticClass:"suitable-content-right"},[_c('div',{staticClass:"suitable-content-right-image2",style:({'background-image': 'url('+_vm.smartEquipmentsData.image2+')',
                     'width':'100%','height':'100%','background-size':'contain',
                     'background-repeat':'no-repeat','background-position':'center'})})])])]),_c('equipment-swiper',{attrs:{"items":_vm.equipmentItems}}),_c('div',{staticClass:"integrated"},[_c('div',{staticClass:"integrated-content"},[_c('div',{staticClass:"integrated-icon"},_vm._l((_vm.integratedData),function(item,index){return _c('div',{key:index,staticClass:"integrated-icon-item",style:({'background': 'url('+item.image+')',
                     'width':'100%','height':'80px','background-repeat':'no-repeat','background-size':'contain','background-position':'center'})})}),0),_vm._m(0),_c('div',{staticClass:"integrated-message"},_vm._l((_vm.integratedData),function(item,index){return _c('div',{key:index,staticClass:"integrated-message-item"},[_c('div',{staticClass:"integrated-message-item-text"},[_vm._v(" "+_vm._s(item.message)+" ")])])}),0),_vm._m(1)])]),_c('div',{staticClass:"integrated-image"},[_c('div',{style:({'background-image': 'url('+this.igImage+')',
                     'width':'100%','height':'100%','background-repeat':'no-repeat','background-size':'contain','background-position':'center'})})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"integrated-line-left"},[_c('div',{staticClass:"line-style"},[_c('div',{staticClass:"dashed-line"},[_c('div',{staticClass:"line-item"})]),_c('div',{staticClass:"circle"},[_c('div',{staticClass:"circle-item"}),_c('div',{staticClass:"circle-item"}),_c('div',{staticClass:"circle-item"}),_c('div',{staticClass:"circle-item"}),_c('div',{staticClass:"circle-item"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"integrated-line-right"},[_c('div',{staticClass:"line-style"},[_c('div',{staticClass:"dashed-line"},[_c('div',{staticClass:"line-item"})]),_c('div',{staticClass:"circle"},[_c('div',{staticClass:"circle-item"}),_c('div',{staticClass:"circle-item"}),_c('div',{staticClass:"circle-item"}),_c('div',{staticClass:"circle-item"}),_c('div',{staticClass:"circle-item"})])])])
}]

export { render, staticRenderFns }