import { render, staticRenderFns } from "./PensionTrends.vue?vue&type=template&id=fec76836&scoped=true&"
import script from "./PensionTrends.vue?vue&type=script&lang=js&"
export * from "./PensionTrends.vue?vue&type=script&lang=js&"
import style0 from "./PensionTrends.vue?vue&type=style&index=0&id=fec76836&prod&scoped=true&lang=css&"
import style1 from "./PensionTrends.vue?vue&type=style&index=1&id=fec76836&prod&scoped=true&lang=css&"
import style2 from "./PensionTrends.vue?vue&type=style&index=2&id=fec76836&prod&scoped=true&lang=css&"
import style3 from "./PensionTrends.vue?vue&type=style&index=3&id=fec76836&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fec76836",
  null
  
)

export default component.exports