<template>
    <div class="service-introduction">
        <!-- <div class="left"> -->
            <div class="left-image"
                 :style="{'background-image': 'url('+introductionData.image+')','width':'100%','height':'100%','background-size':'cover','background-repeat':'no-repeat','background-position':'center'}"/>
        <!-- </div> -->
    </div>
</template>

<script>
    export default {
        name: "ServiceIntroduction1",
        data() {
            return {}
        },
        props: {
            introductionData: Object,
            showTab: {
                type: Boolean,
                default() {
                    return true
                }
            }
        }
    }
</script>

<style scoped>
    .service-introduction {
        width: 50%;
        height: 410;
        margin: 0 auto;
        display: flex;
        box-shadow: 2px 2px 5px #888888;
    }
</style>
