var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('div',{staticClass:"my-bt-img-class"},[_c('div',{staticClass:"bt-content-class"},[_c('div',{staticClass:"bt-left-class"},[_c('h1',[_vm._v("长 期 照 护 专 业 养 老 品 牌")]),_c('div',{staticStyle:{"height":"190px"}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"column-class"},[_c('div',{staticClass:"column-title-class"},[_vm._v(" 椿熙业务 "),_c('ul',[_c('li',{on:{"click":function($event){return _vm.forward("/Company/Community");}}},[_vm._v("社区养老")]),_c('li',{on:{"click":function($event){return _vm.forward("/Company/HomePension");}}},[_vm._v("居家养老")]),_c('li',{on:{"click":function($event){return _vm.forward("/Company/InstitutionalPension");}}},[_vm._v("机构养老")]),_c('li',{on:{"click":function($event){return _vm.forward("/Company/SmartPension");}}},[_vm._v("智慧养老")])])])]),_vm._m(2),_vm._m(3),_vm._m(4)]),_vm._m(5)]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-class"},[_c('div',{staticClass:"column-title-class"},[_vm._v(" 首页 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-class"},[_c('div',{staticClass:"column-title-class"},[_vm._v(" 走进椿熙堂 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-class"},[_c('div',{staticClass:"column-title-class"},[_vm._v(" 椿享家护 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-class"},[_c('div',{staticClass:"column-title-class"},[_vm._v(" 养老动态 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-class"},[_c('div',{staticClass:"column-title-class"},[_vm._v(" 联系我们 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"domain-name-class"},[_vm._v("浙ICP备20003103号")]),_c('div',{staticClass:"network-security-class"},[_c('a',{staticStyle:{"display":"inline-block","text-decoration":"none","height":"20px","line-height":"20px"},attrs:{"target":"_blank","href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33048302000612"}},[_c('img',{staticStyle:{"float":"left"},attrs:{"src":require("@/assets/images/bottom-img/watb.png")}}),_c('p',{staticStyle:{"float":"left","height":"20px","line-height":"20px","margin":"0px 0px 0px 5px","color":"#939393"}},[_vm._v("浙公网安备 33048302000612号")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bt-right-class"},[_c('div',{staticClass:"we-chat-img-class"}),_c('div',{staticClass:"we-char-title-class text-right"},[_vm._v("官方微信公众号")]),_c('div',{staticClass:"text-right text-size"},[_vm._v("400-1576606")]),_c('div',{staticClass:"text-right text-size"},[_c('span',{staticClass:"time-img-class"}),_vm._v("08:30 - 17:30")]),_c('div',{staticClass:"text-right"},[_vm._v("上海椿祺集养老服务有限公司")]),_c('div',{staticClass:"text-right"},[_vm._v("浙江椿熙堂养老服务管理有限公司")])])
}]

export { render, staticRenderFns }