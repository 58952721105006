<template>
    <div>
        <service-introduction1 class="service-introduction1" :introductionData="introductionData" :showTab="showTab"/>

        <div class="middle-content">
            <div class="content">
                <div class="left">
                    <div class="home-name">{{serviceCirculateData.title}}</div>
                    <div class="home-introduction"><p v-html="serviceCirculateData.message"></p></div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
    import ServiceIntroduction1 from "../../components/companyService/ServiceIntroduction1";

    export default {
        name: "HelpTheElderly",
        components: {ServiceIntroduction1},
        data() {
            return {
              serviceCirculateDataImage: {
                backgroundImage: "url(" + require("@/assets/images/helpTheElderly-img/phone_img.jpg") + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
                width: "100%",
                height: "100%"
              },
                showTab: false,
                introductionData: {
                    image: require('@/assets/images/helpTheElderly-img/phone_img.jpg'),
                    // title: '居家养老',
                    // message: '椿熙堂居家板块融合了爱心、关心、暖心、细心、耐心的“五心”服务理，结合了专业的服务技能，积极创新上门服务商业模式与应用场景，' +
                    //     '完善品服务矩阵。在智慧化与标准化的基础上，输出椿熙特色的康护服务体系,赋能生态链伙伴，逐渐向品牌化、规模化、连锁化的方向发展。以标准化' +
                    //     '为基础，智慧化为手段，专业化为常态，医养相结合为特色的服务模式和经营理念，以养老机构为支撑，以社区养老为依托，为各地区居民提供专业的上门' +
                    //     '服务项目。居家板块将持续研发“平台+硬件+特色服务”的产品服务体系，丰富并完善产品应用场景。',
                    // color: '#19548E'
                },
                serviceCirculateData: {
                    // image: require('@/assets/images/homePension-img/circulate.png'),
                    title: '为老助餐服务',
                    message: '为老助餐服务以解决高龄和失能等特殊老年人“吃饭难”问题为重点，充分依托镇（街道）居家养老服务中心、城乡社区居家养老服务照料中心、社会餐饮企业、养老机构等载体，为辖区内有需求的居家养老的老年人提供集中就餐、上门送餐服务，享受“舌尖上的幸福晚年.'+
'椿熙堂从实际运用出发，本着易用、易管理的原则打造本系统，在任何的操作端口都是以一键操作的原则来设计和开发，有着极好的操作体验度。丰富的报表呈现系统和大数据处理后台也可以为相关管理方作出相关助餐决策提供精准的数据支撑。'+
'(一)	产品优势：'+
'1.	中央管理：以行政区域组织架构层级上报汇总数据，按直属辖区监测监控，层级清晰，权限可控'+
'2.	云端一体：云端部署互联网化管理，数据定时备份，范围授权，操作留痕'+
'3.	技术先进：以数据仓库技术为依托，采用ETL、数据填报等方式抽取业务数据并惠及总部数据中心，具有大数据分析、数据安全等特性'+
'(二)	服务优势：'+
'1.	运营支持：依托现有嘉兴团队2年的助餐实操经历，提供成熟经验输出及运营方案支撑，大大减少系统内运营团队和被服务方的时间成本'+
'2.	在线指导：工作时间全程在线咨询解答，做好强力后盾（建议后期属地化人员对接服务）'+
'3.	培训支持：有整套的培训流程和操作手册，按需进行实地或者线上培训'+
'4.	版本更新支持（通用版本）：智慧中心会根据需求方的需求，定期对通用版本进行迭代更新，保证满足日常使用所需'+
'5.	定制开发（可选）：根据需求方个性化需求进行定制化开发'
                },
                }
        },
        methods: {}
    }
</script>

<style scoped>

    .middle-content {
        width: 50%;
        margin: 0 auto;
        padding-top: 30px;
    }

    .content {
        width: 100%;
        height: 220px;
        display: flex;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .right {
        width: 40%;
        height: 220px;
    }

    .right-image {
        margin-left: 20px;
    }

    .left {
        width: 100%;
        height: 220px;
        position: relative;
    }

    .home-name {
        font-size: 16px;
        color: #635E5A;
    }

    .home-introduction {
        margin-right: 20px;
        margin-top: 20px;
        font-size: 12px;
        color: #635E5A;
    }

    .bottom-grid {
        width: 70%;
        margin: 0 auto;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .container {
        display: grid;
        grid-template-columns: 80px 2fr 2fr 2fr;
    }

    .item {
        font-size: 12px;
        border: 1px solid #e5e4e9;
        padding: 5px;
        color: white;
    }

    .item-1, .item-2, .item-3, .item-4 {
        text-align: center;
    }

    .item-1, .item-5, .item-9, .item-13 {
        background-color: #82A6CA;
    }

    .item-2, .item-6, .item-10, .item-14 {
        background-color: #618BBD;
    }

    .item-3, .item-7, .item-11, .item-15 {
        background-color: #517FB1;
    }

    .item-4, .item-8, .item-12, .item-16 {
        background-color: #8FC8BF;
    }
	
	.service-introduction1{
		width: 50%;
		height: 410px;
		margin: 0 auto;
		display: flex;
		/* box-shadow: 2px 2px 5px #888888; */
	}

</style>
