<template>
  <HomePage></HomePage>
</template>

<script>
import HomePage from "@/views/homePage/HomePage";
export default {
  components: {
    HomePage
  }
}
</script>

<style scoped>

</style>
